<template>
    <div>
        <baseInfo :orderBean="orderBean"/>
    </div>
</template>

<script>
import baseInfo from '@/components/openmarketOrder/right/BaseInfo'

export default {
  components: {
    baseInfo
  },
  computed: {
    orderBean () {
      return this.$store.state.openmarketorder.orderBean
    }
  }
}
</script>
