<template>
  <div>
      <Divider dashed ><span class="divider-text">订单基础信息</span></Divider>
      <div class="p-l-10 p-r-10">
        <Row class="p-b-5">
              <i-col span="6" class="title">订单编号</i-col>
              <i-col span="18">{{orderInfo.orderCode}}</i-col>
          </Row>
          <Row class="p-b-5" v-if="orderInfo.omAdvertiserVO">
              <i-col span="6" class="title">投放客户</i-col>
              <i-col span="18">{{orderInfo.omAdvertiserVO.advertiserName}}</i-col>
          </Row>

          <Row class="p-b-5"  v-if="orderInfo.omSalerVO">
              <i-col span="6" class="title">销售</i-col>
              <i-col span="18">{{orderInfo.omSalerVO.userName}}</i-col>
          </Row>
          <Row class="p-b-5">
              <i-col span="6" class="title">订单状态</i-col>
              <i-col span="18">
                <span v-if="orderInfo.status === 0">{{orderInfo.statusName}}</span>
                <Tag v-else :color="getOrderStatusColor(orderInfo.status).bgColor" :style="getOrderStatusColor(orderInfo.status).border ? 'border: 1px solid #fff' : 'none'">{{orderInfo.statusName}}</Tag>
              </i-col>
          </Row>
          <Row class="p-b-5">
              <i-col span="6" class="title">创建时间</i-col>
              <i-col span="18">{{orderInfo.createTime}}</i-col>
          </Row>
          <Row class="p-b-5">
              <i-col span="6" class="title">有效期至</i-col>
              <i-col span="18">{{orderInfo.expireTime}}</i-col>
          </Row>
          <Row class="p-b-5">
              <i-col span="6" class="title p-t-5">发布日期</i-col>
              <i-col span="18"><Tag type="border" color="black">{{orderInfo.startDate}} 至 {{orderInfo.endDate}} </Tag></i-col>
          </Row>
      </div>

  </div>
</template>

<script>
import { getOrderStatus } from '@/utils/tagStatus'

export default {
  props: {
    orderBean: {
      type: Object,
      required: true
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  components: {
  },
  data () {
    return {
      orderInfo: {}
    }
  },
  created () {
    this.orderInfo = this.orderBean
  },
  methods: {
    getOrderStatusColor (status) {
      return getOrderStatus(status)
    }
  },
  watch: {
    orderBean (val) {
      this.orderInfo = this.orderBean
    }
  }
}
</script>

<style>
.conflictTable .ivu-table-cell{
  padding-left: 5px;
  padding-right: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-all;
  box-sizing: border-box;
}
</style>
